export default function HeroSection() {
    return (
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            {/* <p className="section--title">Hey, my name is</p> */}
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">Hi, my name is Rehean Thillai</span>{" "}
              <br />
            </h1>
            <p className="hero--section-description">
              A software engineering student from McGill University.
              <br /> 
            </p>
          </div>
          <div className="resume-button-container">
            <a href="./documents/resume.pdf" target="_blank" rel="noopener noreferrer">
              <br />
              <button className="btn btn-primary">My Resume</button>
            </a>
          </div>
        </div>
        <div className="hero--section--img">
          <img src="./img/home2.png" alt="Picture of Me" />
        </div>
      </section>
    );
  }
  