
export default function AboutMe() {
    return(
        <section id="AboutMe" className="about--section">
            <div className="about--section--img">
                <img src="./img/about_me2.png" alt="About Me" />
            </div>
            <div className="hero--section--content--box about--section--box">
                <div className="hero--section--content">
                    {/* <p className="section--title">About</p> */}
                    <h1 className="skills-section--heading">About Me</h1>
                    <p className="hero--section-description">
                    Hi, I’m Rehean (I go by Rey)! 👋. I'm a passionate third-year software engineering student at McGill University with a love for problem-solving and innovation. My curiosity drives me to explore how technology can make life easier for both businesses and individuals. Outside of coding, you’ll find me diving into the latest tech trends, experimenting with new cooking recipes, staying active at the gym, or traveling and spending quality time with friends.
                    </p>
                    <p className="hero--section-description">
                    Throughout my journey, I've gained valuable industry experience from two full-stack internships at Libro, where I worked on a SaaS product that supports thousands of restaurants globally. Alongside my internships, I’ve also pursued freelance software development, including creating a lead management tool for a team of 26 insurance agents at Industrial Alliance. My diverse experiences have shaped my ability to deliver impactful tech solutions that drive results.
                    </p>
                </div>
            </div>
      </section>
    )
}